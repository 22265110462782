<template>
  <base-section id="article" space="72">
    <v-container class="py-0">
      <v-row justify="center">
        <v-col cols="12" md="7">
          <news-card class="mb-4" prominent v-bind="article" />

          <news-share />

          <!-- <news-author v-bind="article.author" /> -->

          <news-comments />

          <news-comment-reply />
        </v-col>

        <v-col class="hidden-sm-and-down" cols="3">
          <news-search />

          <news-categories />

          <news-recent-articles />

          <news-archives />

          <news-tags />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionNews",

  components: {
    NewsArchives: () => import("@/components/news/Archives"),
    NewsRecentArticles: () => import("@/components/news/RecentArticles"),
    NewsAuthor: () => import("@/components/news/Author"),
    NewsCard: () => import("@/components/news/Card"),
    NewsCategories: () => import("@/components/news/Categories"),
    NewsCommentReply: () => import("@/components/news/CommentReply"),
    NewsComments: () => import("@/components/news/Comments"),
    NewsSearch: () => import("@/components/news/Search"),
    NewsShare: () => import("@/components/news/Share"),
    NewsTags: () => import("@/components/news/Tags"),
  },
  mounted() {
    // fetch the blog post content based on the slug parameter
    // this.article = this.$route.params.article;
    // console.log("Article is: ", this.article);
  },

  computed: {
    truncatedText() {
      if (!this.text) return "";

      const truncate = Number(this.truncate);

      return this.text.length > truncate
        ? this.text.slice(0, truncate) + " [...]"
        : this.text;
    },
  },

  props: {
    article: {
      type: Object,
      default: () => ({}),
    },
  },

  // data: () => ({
  //   article: {},
  // }),
};
</script>
